const HOME_CONTEXT = {
  TITLE:
    "US AIR Force recognizes SPACEMAP Pioneers Collision Avoidance in Space",
  DESCRIPTION: [
    "We are developing a real-time decision-making platform for the safety and efficiency of space assets. The quality of our solutions is no worse than the best-known commercial software."
  ]
  // DESCRIPTION: [
  //   `SPACEMAP, a South Korean space technology company led by Dr. Douglas Deok-Soo Kim, has
  //   developed transformative space collision prevention methods. In a landscape where
  //   satellites navigate increasingly crowded orbits, the risk of collisions becomes a
  //   pressing concern.`,

  //   `Despite the complexities posed by the surge in space catalog size, SpaceMap tackles
  //   these challenges by providing innovative solutions for real-time conjunction assessment
  //   and near real-time collision avoidance. At the core of SpaceMap’s innovative solution
  //   lies the Voronoi diagram, renowned for its efficiency in spatiotemporal reasoning across
  //   both 2D and 3D spaces. The technology utilizes a dynamic Voronoi diagram, enabling
  //   real-time definition of neighborhoods for moving points, such as satellites.`
  // ]
};

const SPACETALK_CONTEXT = {
  SUBTITLE: `the virtual meeting & collabo platform with the 4D space-time
            modeling of satellites and debris`,
  DESCRIPTION: [
    `42 Talks enables sharing critical events among 
    stakeholders using internet-based, synchronized 3D 
    visualization of satellites among debris. Participants can
    have virtual meetings to exchange ideas through texts,
    voices, and videos.`,
    `Conjunctions and maneuvers are examples of such events.
    With 42 Talks, decision alternatives are evaluated in real-
    time, e.g., maneuver trajectory alternatives that account
    for tertiary conjunctions.`,
    `The sad space story of sharing space situations via only
    emails and phone calls should be stopped: e.g., the 2021
    Starlink-OneWeb conflict - This continues today.`
  ]
};

const ASTRO1_CONTEXT = {
  DESCRIPTION: [
    `Space is limited human resource. We intend to
    dramatically lower the hurdle to access the safety
    decision-making platform for space. Astro-1 will be
    the global space infrastructure for sustainable space.`,

    `It features conjunction assessment and optimal
    collision avoidance for all space vehicles for
    both orbital and non-orbital motions in a unified
    algorithm. Non-orbital motions include launches,
    orbit transfers, reentry, etc.`
  ]
};

const STAI_CONTEXT = {
  TITLE: "Space-Time AI (ST-AI)",
  SUBTITLE: `is our AI technology for the real-time solution of 
    spatiotemporal problems of space assets in timeline.`,
  DESCRIPTION: `We use the Voronoi diagrams as an analytic engine for near-term 
    decisions and the Deep Learning fused with the Voronoi diagram 
    as an inference engine for long-term ones. We do not use the 
    traditional three-filter algorithm.`
};

const VORONOI_CONTEXT = {
  TITLE: "Voronoi diagram",
  SUBTITLE: "is the underpinning technology.",
  DESCRIPTION1: `It is a tessellation of space to represent particles' proximity
    in the most concise data structure for efficient spatial reasoning.`,
  DESCRIPTION2: [
    `We use its dynamic generalization for moving 3D space
    objects for spatiotemporal reasoning, i.e., space-time.
    Among all moving particles, the red & blue in the circular
    probe of the moving green can be found by the distance check
    of the yellow ones.
    This takes only O(1) time with the Voronoi diagram, i.e.,
    real-time even for many particles. The biggest empty dotted
    circle with its center in the probe can also be found in O(1)
    time.`,

    `New Space Age applications to be found will require this powerful
    real-time space-time reasoning capability.`
  ]
};

const RESOURCE_CONTEXT = {
  TITLE: `We are pioneering the Space-Time AI technology fused with Voronoi
    diagram in space industry.`,
  DESCRIPTION: [
    `We believe our method would be able to replace the existing
    method of predicting satellite orbits, which has been in wide
    use for the past 40 years. Unlike the traditional model that
    bases its predictions on one-to-one relations between each
    point, SPACEMAP’s Voronoi-applied model can calculate distances
    between a point and all of its adjacent points.`,

    `Check out how we introduced our advancing space technology.`
  ]
};

const ABOUT_CONTEXT = {
  MAIN: "We are a leading K-Space company.",
  SUB: [
    `SPACEMAP was founded in 2021 based on the idea for
    spatiotemporal reasoning problems, initiated by the
    research funded by the US AFOSR/AFRL  about conjunction
    assessment and collision avoidance  since 2016.`,

    `Voronoi diagrams are our underpinning technology. Being
    one of the leading scholar, our Founder & CEO Douglas
    Kim has developed the theory and algorithms, implemented
    libraries and programs, and solved diverse application
    problems using Voronoi diagrams. The applications span
    from atomic/molecular-level biology problems to
    galaxy-level cosmic problems. This basic research has
    long been supported by the Leader Research Initiative,
    Creative Research Initiative, and National Research Lab
    of Ministry of Science and ICT, Korea since 2003.`,

    `SPACEMAP won the Deep Tech TIPS (Tech Incubator Program for Startup)
    grant from the Ministry of SMEs and Startups in 2023.`
  ]
};

const SAUDI_CONTEXT = {
  MAIN: "SPACEMAP is One of 100 Innovative Companies Advancing to the Entrepreneurship World Cup Global Finals",
  SUB: [
    `SPACEMAP is one of 100 up-and-coming companies representing
     52 countries and territories selected to travel to Riyadh, 
     Saudi Arabia to compete in the Entrepreneurship World Cup 
     Global Finals November 5-9.`,

     `The competition, co-hosted by the Global Entrepreneurship Network and 
     Saudi Arabia’s Small and Medium Enterprises General Authority, 
     Monsha’at, is one of the largest and most diverse pitch competitions and 
     support programs of its kind. Every year, tens of thousands of entrepreneurs 
     from 200 countries and territories compete for US$1 million in cash prizes 
     and millions more worth of investment, support and publicity through national 
     pitch competitions, bootcamps and a global finals event attended by leading investors. `
  ]
}

const MISSION_CONTEXT = {
  MAIN: "SPACEMAP Mission & Vision",
  SUB: [
    `Our mission is to present a real-time, autonomous & convenient
    software for safer, more efficient & sustainable space. We will
    produce the best-possible solutions in real-time or near real-time
    for all decision-making problems in space.`,

    `Investments to space ecosystem is sustainable only if prior
    investments are profitable which is possible only with good
    software. SPACEMAP technology can help all stakeholders create
    diverse pieces of real-time space software quickly and conveniently.`
  ]
};

const CONJUNCTION_TABLE_CONTEXT = {
  TITLE: "Top-100 conjunctions in timeline",
  DESCRIPTION: "(Next 48 hours; Sorted in DCA)"
};

const OPTION_LIST = [
  { label: "GLOBAL", value: "" },
  { label: "USA", value: "us" },
  { label: "CHINA", value: "prc" },
  { label: "KOREA", value: "skor" },
  { label: "ISS", value: "ISS%20(ZARYA)" },
  { label: "STARLINK", value: "starlink" }
];

export { OPTION_LIST, CONJUNCTION_TABLE_CONTEXT, MISSION_CONTEXT, SAUDI_CONTEXT, ABOUT_CONTEXT, RESOURCE_CONTEXT, VORONOI_CONTEXT, STAI_CONTEXT, ASTRO1_CONTEXT, SPACETALK_CONTEXT, HOME_CONTEXT }