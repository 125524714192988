import styled from "styled-components";
import { SNS_ITEMS } from "@/constants/contact";

const SNS = () => {
  return (
    <SNSWrapper>
      {SNS_ITEMS.map((item, index) => {
        return (
          <SNSItem key={`SNS${index}`}>
            <SNSImg>
              <a href={item.link} target="blank">
                <img src={item.src} alt="" />
              </a>
            </SNSImg>
            {item.name}
          </SNSItem>
        );
      })}
    </SNSWrapper>
  );
};

export default SNS;

const SNSWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const SNSItem = styled.article`
  width: auto;
  height: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SNSImg = styled.section`
  width: 3em;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4d5257;
  a,
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
