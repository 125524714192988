const generateTickValues = (start, interval, hours) => {
  const tickValues = [];
  const end = new Date(start);
  end.setHours(start.getHours() + hours);

  for (let d = new Date(start); d <= end; d.setHours(d.getHours() + interval)) {
    tickValues.push(new Date(d));
  }

  return tickValues;
};

const transformData = conjunctionData => {
  return conjunctionData.map(cdm => ({
    id: cdm.poc,
    data: [
      {
        x: new Date(cdm.tcaTime.split("\n").join("T")),
        y: cdm.dca,
        ...cdm
      }
    ]
  }));
};

const findMaxMin = conjunctionData => {
  let max = 1;
  let min = 0.0000000001;
  conjunctionData.forEach(data => {
    if (data.id > max) max = data.id;
    if (data.id < min) min = data.id;
  });

  return { max, min };
};

const calculateColor = (value, maxmin) => {
  const colors = [
    { r: 0, g: 0, b: 130 }, // Navy
    { r: 0, g: 0, b: 255 }, // Blue
    { r: 0, g: 255, b: 255 }, // Cyan
    { r: 0, g: 128, b: 0 }, // Green
    { r: 255, g: 255, b: 0 }, // Yellow
    { r: 255, g: 165, b: 0 }, // Orange
    { r: 255, g: 0, b: 0 } // Red
  ];
  if (value <= 0.000001) {
    return `rgb(0, 0, 130)`;
  }

  // Apply logarithm to value, min, and max
  const logValue = Math.log10(value);
  const logMin = Math.log10(maxmin.min);
  const logMax = Math.log10(maxmin.max);

  // Normalize based on log scale
  const normalizedValue = (logValue - logMin) / (logMax - logMin);

  const index = Math.floor(normalizedValue * (colors.length - 1));

  const startColor = colors[index];
  const endColor = colors[index + 1] || colors[index];

  const percentage = normalizedValue * (colors.length - 1) - index;

  const r = Math.round(startColor.r + (endColor.r - startColor.r) * percentage);
  const g = Math.round(startColor.g + (endColor.g - startColor.g) * percentage);
  const b = Math.round(startColor.b + (endColor.b - startColor.b) * percentage);

  return `rgb(${r}, ${g}, ${b})`;
};

const formatDate = date => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const monthIndex = date.getMonth();
  const day = date.getDate();

  return `${months[monthIndex]} ${day < 10 ? "0" + day : day}`;
};

export { formatDate, calculateColor, transformData, findMaxMin, generateTickValues }