const NEWS = [
  {
    img: "/article/September 21, 2024.png",
    date: "September 21, 2024",
    title: `GGSS lecturer : Douglas Deok-Soo Kim, CEO of Space Map (Aug. 2024)`,
    content: `Douglas Deok-Soo Kim Professor at Hanyang University School of Mechanical  Engineering He is a Founder of Space Map, dreaming of the 'Google Map of Space'`,
    link: "https://saebeomi.blogspot.com/2024/09/ggss-lecturer-prof-douglas-deok-soo-kim.html",
    linkedIn: ""
  },
  {
    img: "/article/February 15, 2024.png",
    date: "February 15, 2024",
    title: `US AIR Force recognizes SpaceMap Pioneers Collision Avoidance in Space`,
    content: `In 2021, SpaceMap, a pioneering space technology company, emerged in 
      South Korea under the leadership of Dr. Douglas Kim. The genesis of
      SpaceMap can be traced back …`,
    link: "https://briccdc.com/bricc-news/spacemap-pioneers-collision-avoidance-in-space",
    linkedIn:
      "https://www.linkedin.com/posts/briccdc_spacemap-pioneers-collision-avoidance-in-activity-7169102735517171713-6_29/?utm_source=share&utm_medium=member_desktop"
  },
  {
    img: "/article/November 21, 2023.png",
    date: "November 21, 2023",
    title: `(Korean) "The Guardian to Space and the Traffic Light of the Universe"`,
    content: `Discover how SPACEMAP's cutting-edge 'Dynamic Voronoi Diagram' technology
       is set to become the essential software for space companies worldwide in
       this article by The Chosunilbo …`,
    link: "https://biz.chosun.com/science-chosun/technology/2023/11/21/M5HHYSNTTVB6JLZONHCEXHWWXM/",
    linkedIn: ""
  },
  {
    img: "/article/November 8, 2023.png",
    date: "November 8, 2023",
    title: `(Korean) South Korean-US space alliance expands to the private sector`,
    content: `The potential collaboration with American space startups is set to 
      revolutionize satellite collision avoidance services globally …`,
    link: "https://www.hankyung.com/article/2023110825381",
    linkedIn: ""
  },
  {
    img: "/article/July 28, 2023.png",
    date: "July 28, 2023",
    title: `South Korean Startup to revolutionize satellite orbit prediction`,
    content: `Spacemap, a startup based in South Korea, aims to revolutionize 
      space missions by enhancing safety through its advanced platform 
      technology. The company’s platform can predict satellite orbits 
      and space debris movements …`,
    link: "https://geospatialworld.net/news/spacemap-satellite-orbit-prediction/",
    linkedIn: ""
  },
  {
    img: "/article/July 23, 2023.png",
    date: "July 23, 2023",
    title: `Spacemap looks to revolutionize satellite orbit prediction`,
    content: `Spacemap, a South Korean startup, is looking to bring about 
      an evolution that makes space missions safer with its platform 
      technology that can predict the orbits of satellites and space 
      debris to prevent collisions. …`,
    link: "https://www.koreaherald.com/view.php?ud=20230723000103",
    linkedIn: ""
  }
];

const NUM_NEWS = NEWS.length;

export { NEWS, NUM_NEWS }