import styled from "styled-components";
import { PageLayout } from "@/components/ui/PageTemplate";

const Layout = styled(PageLayout)`
  width: 73.125rem;
  min-width: 700px;
  height: 43.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  margin: auto;
`;

const TeamItem = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  gap: 1rem;

  flex-shrink: 0;
  width: 6rem;
  height: 14rem;
`;

const ImgWrapper = styled.section`
  width: 100%;
  height: 60%;
  overflow: hidden;
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #000000;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  object-fit: cover;
  object-position: center top;
  filter: grayscale(100%);
`;

const Info = styled.section`
  height: 5rem;
`;

const Name = styled.div`
  color: #000000;
  font-weight: 800;
  font-size: 0.9rem;
`;

const Role = styled.div`
  color: #000000;
  font-weight: 500;
  font-size: 0.7rem;
`;

const SubContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export { SubContainer, Layout, Img, ImgWrapper, Role, Name, Info, TeamItem, Title }