import styled from "styled-components";
import { PageLayout, Title } from "@/components/ui/PageTemplate";

const Layout = styled(PageLayout)`
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

const DesriptionWrapper = styled.section`
  width: 87%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const STAITitle = styled(Title)`
  line-height: 2rem;
`;

const Description = styled.article`
  font-size: 1.3rem;
  font-weight: 300;
  color: white;
  text-align: justify;
`;

const ImgWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Layout, DesriptionWrapper, STAITitle, Description, ImgWrapper }