const FAQ = [
  {
    question: "What is this calendar for?",
    answer:
      "Space Calendar facilitates easy post and retrieval of space events. Anyone can use it freely."
  },
  {
    question: "What will be the contributions to the community?",
    answer:
      "One can post and retrieve events through simple and intuitive user interfaces. Posting here will make the entire community aware of the events to attact more participants. Retrieved events can be copied to user’s calendar with a single click - This will make user’s life significantly easier."
  },
  {
    question: "Can I upload files? ",
    answer: "Yes. You can upload a brochure, flyer, document or similar."
  }
];

export { FAQ }