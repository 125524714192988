import React, { useEffect, useState } from "react";
import useWindowSize from "@/hooks/page/useWindowSize";
import Articles from "@/components/news/Articles";
import {
  NewsPageTemplate,
  Layout,
  NewsPageTitleWrapper,
  NewsPageTitle,
} from "./newsStyle";
import useObserver from "@/hooks/page/useObserver";

const News = () => {
  const { width, height } = useWindowSize();
  const [newsIndex, setIndex] = useState(0);
  useObserver("content7");

  useEffect(() => {
    const initializeNews = event => {
      const index = event.detail.index;
      index === 7 && setIndex(0);
    };

    document.addEventListener("onChangeMenu", initializeNews);

    return () => {
      document.removeEventListener("onChangeMenu", initializeNews);
    };
  }, []);

  return (
    <NewsPageTemplate
      id="Press & Article"
      $width={width}
      $height={height}
      $backgroundcolor={"#ffffff"}
    >
      <Layout id="content7">
        <NewsPageTitleWrapper>
          <NewsPageTitle>
            How <strong>SPACEMAP</strong> is recognized
          </NewsPageTitle>
        </NewsPageTitleWrapper>
        <Articles newsIndex={newsIndex} />
      </Layout>
    </NewsPageTemplate>
  );
};

export default React.memo(News);
