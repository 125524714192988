import styled from "styled-components";
import { PRODUCT_LINKS } from "@/constants/navBar";

const ProductNavBar = () => {
  return (
    <ProductNav>
      <ul>
        {PRODUCT_LINKS.map((link, index) => {
          return (
            <a
              href={link.url}
              target={link.key === "Platform" ? "_blank" : ""}
              key={index}
            >
              <ProductItems>{link.key}</ProductItems>
            </a>
          );
        })}
      </ul>
    </ProductNav>
  );
};

export default ProductNavBar;

const ProductNav = styled.nav`
  margin-left: auto;
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    line-height: 1;
    gap: 3rem;
    padding: 0;
    text-align: center;
    > a {
      text-decoration: none;
    }
  }
`;

const ProductItems = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fccb15;
  font-size: 1.3rem;
  font-weight: 700;
  &:hover {
    transform: scale(0.9);
  }
`;
