import React, { useState } from "react";

import styled from "styled-components";
import {
  AdvancedSearchStateType,
  useAdvancedInput
} from "@/hooks/search/useAdvancedInput";
import { useInput } from "@/hooks/search/useInput";
import { PALETTE } from "@/styles/theme";

export enum SearchType {
  SEARCH,
  ADVANCED_SEARCH
}

export type TableQueryKeyType = {
  searchType: SearchType;
  inputValue?: string;
  advancedInputValue?: AdvancedSearchStateType;
};

export const Search = () => {

  const {
    input: inputValue,
    onChange,
    setInput,
    onFocus,
    onBlur,
    isFocus
  } = useInput("");

  const {
    input: advancedInputValue,
    handleChangeAdvancedInputValue,
    minTCA,
    maxTCA,
    predictionWindowString
  } = useAdvancedInput();

  const [tableQueryKey, setTableQueryKey] = useState<TableQueryKeyType>({
    searchType: SearchType.SEARCH
  });

  const handleSearch = () => {
    setTableQueryKey({ searchType: SearchType.SEARCH, inputValue });
  };

  const handleAdvancedSearch = () => {
    setTableQueryKey({
      searchType: SearchType.ADVANCED_SEARCH,
      advancedInputValue
    });
  };

  return (
    <Section className="conjunction-table-section">
      {/* <ConjunctionTable queryKey={tableQueryKey} limit={5} totalPage={20} /> */}

      {/* <SearchTypeController className='search-controller'>
        <SearchTypeButton
          selected={searchType === SearchType.SEARCH}
          onClick={() => setSearchType(SearchType.SEARCH)}>
          Search
        </SearchTypeButton>
        <SearchTypeButton
          selected={searchType === SearchType.ADVANCED_SEARCH}
          onClick={() => setSearchType(SearchType.ADVANCED_SEARCH)}>
          Advanced Search
        </SearchTypeButton>
      </SearchTypeController>

      {searchType === SearchType.SEARCH ? (
        <SearchContainer className='search-container'>
          <AutoSuggestionSearchInput
            inputValue={inputValue}
            handleChange={onChange}
            setInput={setInput}
            onFocus={onFocus}
            onBlur={onBlur}
            isFocus={isFocus}
          />
          <PrimaryButton className='submit-button' onClick={handleSearch}>
            Search
          </PrimaryButton>
        </SearchContainer>
      ) : (
        <AdvancedSearchContainer className='advanced-search-container'>
          <Description className='advanced-search-description'>
            {predictionWindowString}
          </Description>

          <AdvancedSearchInput
            input={advancedInputValue}
            handleChangeValue={handleChangeAdvancedInputValue}
            minTCA={minTCA}
            maxTCA={maxTCA}
          />

          <PrimaryButton className='submit-button' onClick={handleAdvancedSearch}>
            Search
          </PrimaryButton>
        </AdvancedSearchContainer>
      )} */}
    </Section>
  );
};

export default Search;

const AdvancedSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  gap: 8px;
`;

const SearchTypeController = styled.div`
  display: flex;
  cursor: pointer;
  > :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const SearchTypeButton = styled.button<{ selected }>`
  padding: 0.5rem 1rem;
  border: 1px solid gray;

  background-color: ${({ selected }) =>
    selected
      ? PALETTE.maze.default
      : `${PALETTE.grey.dark300}${PALETTE.opacity[40]}`};
  color: ${({ selected }) => (selected ? "black" : "white")};
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${PALETTE.maze.default};
    color: black;
  }
`;

const Section = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Description = styled.div`
  display: flex;

  color: #fff;
  font-size: 12px;
  text-align: center;

  white-space: pre-wrap;
`;
