import styled from "styled-components";
import { PageLayout, Title } from "@/components/ui/PageTemplate";

const Layout = styled(PageLayout)`
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 1rem;
`;

const AstroTitle = styled(Title)`
  line-height: 2rem;
`;

const AstroOrcaImg = styled.img`
  width: 5rem;
  height: 5rem;
`;

const DescriptionWrapper = styled.section`
  width: 35rem;
  height: 24.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

const VideoWrapper = styled.section`
  width: 30rem;
  height: 24.25rem;
  box-sizing: border-box;
  padding-bottom: 0.5rem;

  display: flex;
`;

const Video = styled.video`
  width: 100%;
  pointer-events: none;
  place-self: end;
`;

export { Layout, AstroTitle, AstroOrcaImg, DescriptionWrapper, Video, VideoWrapper}