import React, { useState, useEffect, useRef } from "react";
import PlatformLink from "@/components/ui/button/PlatformLink";
import { ASTRO1_CONTEXT } from "@/constants/context";
import PageTemplate, {
  SubTitle,
  TitleWrapper,
  Description,
  Emphasize
} from "@/components/ui/PageTemplate";
import {
  Layout,
  Video,
  ContentWrapper,
  AstroTitle,
  ConjunctionWrapper,
  DescriptionWrapper
} from "./astro1Style";
import useObserver from "@/hooks/page/useObserver";

const Astro1 = () => {
  const [isTableActive, setTableActive] = useState(false);
  const videoRef = useRef(null);
  useObserver("content1");

  useEffect(() => {
    const initializeNews = event => {
      const index = event.detail.index;
      if (index === 1) {
        setTableActive(false);
        videoRef.current.currentTime = 0;
      }
    };

    document.addEventListener("onChangeMenu", initializeNews);

    return () => {
      document.removeEventListener("onChangeMenu", initializeNews);
    };
  }, []);

  return (
    <PageTemplate id="Astro-1">
      <Layout id="content1">
        <ConjunctionWrapper $offset={isTableActive ? "-32.1rem" : "0"}>
          <Video ref={videoRef} muted autoPlay loop>
            <source
              src="/video/AstroOne.mp4"
              type="video/mp4"
            />
          </Video>
        </ConjunctionWrapper>
        <ContentWrapper>
          <DescriptionWrapper>
            <TitleWrapper>
              <AstroTitle>
                <strong>
                  Astro-<Emphasize>1</Emphasize>
                </strong>
                ,<br />
                <SubTitle>the real-time guardian for space safety</SubTitle>
              </AstroTitle>
            </TitleWrapper>
            {ASTRO1_CONTEXT.DESCRIPTION.map((item, index) => {
              return <Description key={index}>{item}</Description>;
            })}
            <PlatformLink />
          </DescriptionWrapper>
        </ContentWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Astro1);
