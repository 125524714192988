import styled from "styled-components";
import { PageLayout } from "@/components/ui/PageTemplate";

const VideoWrapper = styled.section<{
  $height: number;
}>`
  position: absolute;
  top: 0;
  width: 100vw;
  height: ${props => `${props.$height}px`};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    width: 700px;
    height: 500px;
    bottom: calc((500px - 100%) * -1 / 2);
  }
`;

const Video = styled.video`
  width: 150rem;
  height: 200rem;
  object-position: center bottom;
  pointer-events: none;
`;

const Layout = styled(PageLayout)`
  height: calc(100vh - 5rem);
  gap: 1rem;
  z-index: 1;
`;

const Title = styled.header`
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-top: 3rem;

  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  /* backdrop-filter: blur(8px); */
  background-color: #00000080;
  border-radius: 10px;
`;

const DescriptionSection = styled.main`
  /* width: 35rem; */
  width: 33rem;
  margin-left: auto;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  border-radius: 10px;
  background-color: #00000080;
`;

const Description = styled.article`
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  text-align: justify;
`;

const Button = styled.div`
  width: 8rem;
  padding: 0.3rem 0.5rem;
  border: 0.0625rem solid #ffffff;
  border-radius: 0.625rem;

  display: flex;
  justify-content: center;
  cursor: pointer;
  > a {
    color: #ffffff;
    font-size: 1.3rem;
    text-align: center;
    text-decoration: none;
  }
`;

export { Button, Description, DescriptionSection, TableWrapper, Content, Title, Layout, Video, VideoWrapper}