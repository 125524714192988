import styled from "styled-components";
import useWindowSize from "@/hooks/page/useWindowSize";
import Logo from "./Logo";
import ProductNavBar from "./ProductNavBar";

const MobileNavBar = () => {
  const { width } = useWindowSize();

  return (
    <NavBarWrapper $width={width}>
      <Logo />
      <ProductNavBar />
    </NavBarWrapper>
  );
};

export default MobileNavBar;

const NavBarWrapper = styled.header<{
  $width: number;
}>`
  position: fixed;
  top: 0;
  box-sizing: border-box;

  width: ${props => `${props.$width}px`};
  min-width: 700px;
  height: 5rem;
  padding: 0 3rem;

  background-color: #000000;
  z-index: 10;

  display: flex;
  align-items: center;
`;
