import SpaceCalendarLogo from "@/components/shared/logo/SpaceCalendarLogo";
import { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import styled from "styled-components";
import Menu from "./Menu";
import { isMobile } from "react-device-detect";

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      {isMobile && isMenuOpen && (
        <ModalBackGround
          onClick={() => {
            setIsMenuOpen(false);
          }}
        />
      )}
      <Header $isopen={isMenuOpen}>
        <RxHamburgerMenu
          size={24}
          color="#ffffff"
          style={{ position: "fixed", left: "2rem" }}
          onClick={() => {
            setIsMenuOpen(true);
          }}
        />
        <SpaceCalendarLogo />
      </Header>
    </>
  );
};

export default MobileMenu;

const Header = styled.header<{ $isopen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 7rem;
  background-color: #001326;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  /* transform: translateX(${({ $isopen }) => (!$isopen ? "0%" : "-100%")});
  transition: transform 0.5s ease-in-out; */
`;

const ModalBackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: #000000c0;
`;
