import styled from "styled-components";
import { NEWS } from "@/constants/news";

const Articles = ({ newsIndex }) => {
  return (
    <ArticleWrapper $index={newsIndex}>
      {NEWS.map((news, index) => {
        return (
          <Article key={`news${index}`}>
            <ImageWrapper>
              <Image src={news.img} alt="news img" />
            </ImageWrapper>
            <Date>{news.date}</Date>
            <ArticleContent>
              <ArticleTitle>{news.title}</ArticleTitle>
              {news.content}
            </ArticleContent>
            <LinkSection>
              <ReadMoreButton
                onClick={() => {
                  window.open(news.link, "_blank");
                }}
              >
                Read More
              </ReadMoreButton>
              {news.linkedIn && (
                <LinkedInLink
                  onClick={() => {
                    window.open(news.linkedIn, "_blank");
                  }}
                >
                  <img src="/svg/linkedIn-white.svg" alt="linkedIn" />
                </LinkedInLink>
              )}
            </LinkSection>
          </Article>
        );
      })}
    </ArticleWrapper>
  );
};

export default Articles;

const ArticleWrapper = styled.section<{
  $index: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  transform: translateY(calc(${props => `${props.$index}`} * 15rem * -1));
  transition: transform ease 0.5s;
`;

const Article = styled.article`
  width: 73rem;
  height: 13rem;
  padding: 1rem 0;

  display: grid;
  grid-template-columns: [column1]13rem[column2]1fr[column3];
  grid-template-rows: [row1]1.5rem[row2]7rem[row3]2rem[row4];
  column-gap: 3rem;
`;

const Date = styled.span`
  font-size: 1rem;
  color: gray;
`;

const ArticleTitle = styled.h3`
  margin: 0 0 1rem 0;
  font-style: italic;
  font-size: 1.5rem;
  color: #000000;
`;

const ArticleContent = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  overflow: auto;
`;

const ReadMoreButton = styled.div`
  width: 7rem;
  height: 2rem;

  box-sizing: border-box;
  border: 0.0625rem solid #000000;
  border-radius: 0.625rem;

  font-size: 1.2rem;
  color: #000000;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.section`
  grid-area: row1/column1/row4/column2;
`;

const Image = styled.img`
  width: 13rem;
  height: 13rem;

  object-fit: cover;
  object-position: right center;
`;

const LinkSection = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const LinkedInLink = styled.div`
  width: 2rem;
  height: 2rem;

  border-radius: 0.625rem;
  background-color: #0b65c2;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  img {
    width: 1rem;
    padding: 0.1rem 0;
  }
`;
