import React from "react";
import { Emphasize } from "@/components/ui/PageTemplate";
import {
  ResourceTitle,
  ResourceDescription
} from "@/page/technology/resourcesStyle";

const ResourceHeader = ({ index, resource }) => {
  return (
    <ResourceTitle>
      {`${index + 1}. `}
      {resource.title.split("$").map((part, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 1 ? <span>{part}</span> : <Emphasize>{part}</Emphasize>}
          </React.Fragment>
        );
      })}
      {index === 4 && (
        <ResourceDescription>
          (formerly known as AstroFingertip)
        </ResourceDescription>
      )}
    </ResourceTitle>
  );
};

export default ResourceHeader;
