import styled from "styled-components";

const STAIImg = () => {
  return (
    <ImgWrapper>
      <Img src="/technology/STAI.png" alt="Stai" />
      <Video muted autoPlay loop>
        <source
          src="/technology/STAI-AE.mp4"
          type="video/mp4"
        />
      </Video>
    </ImgWrapper>
  );
};

export default STAIImg;

const ImgWrapper = styled.section`
  position: relative;
  width: 73.125rem;

  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
`;

const Video = styled.video`
  position: absolute;
  width: 18.8rem;
  top: 4.5rem;
  left: 17.7rem;
  pointer-events: none;
  @media all and (max-width: 899px) {
    width: 20.1rem;
    top: 4.7rem;
    left: 18.9rem;
  }
  @media all and (max-width: 700px) {
    width: 20.5rem;
    top: 4.5rem;
    left: 19.2rem;
  }
`;
