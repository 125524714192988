import { SvgWrapper } from "@/page/technology/resourcesStyle";
import { FaDownload } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const DownloadSvg = ({ resource }) => {
  return (
    <SvgWrapper>
      <a href={resource.link} target="_blank" rel="noreferrer">
        {resource.type === "pdf" ? (
          <FaDownload size={"1.2rem"} color="black" />
        ) : (
          <FaYoutube size={"1.5rem"} color="black" />
        )}
      </a>
    </SvgWrapper>
  );
};

export default DownloadSvg;
