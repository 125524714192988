const PALETTE = {
  midnightBlue: {
    default: "#001326",
    light100: "#a4acbb",
    light200: "#323954",
    light300: "#a4acbb",
    light400: "#f0f2f5"
  },
  maze: {
    default: "#fccb16",
    light100: "#f5d76d",
    light200: "#eae3c6"
  },
  grey: {
    dark300: "#434343",
    dark200: "#545454",
    dark100: "#7c7c7c",
    default: "#959595",
    light100: "#c9c9c9",
    light200: "#e2e2e2"
  },
  red: {
    default: "#ff0000",
    light100: "#ff5353",
    light200: "#ffcaca"
  },
  white: "#ffffff",
  black: "#000000",

  opacity: {
    0: "00",
    5: "0D",
    10: "1A",
    13: "21",
    18: "2E",
    20: "33",
    30: "4D",
    40: "66",
    47: "78",
    50: "80",
    60: "99",
    70: "B3",
    80: "CC",
    90: "26",
    100: "FF"
  }
};

export { PALETTE }