import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import {
  calculateColor,
  formatDate,
  transformData
} from "./conjunctionCalendarComponentUtils";
import TableLoadingSpinner from "@/components/ui/table/TableLoadingSpinner";
import {
  useChartInteraction,
  useGetTimeValues
} from "./conjunctionCalendarComponentHooks";
import ConjunctionCalendarTooltip from "@/components/core/tooltip/ConjunctionCalendarTooltip";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const ConjunctionCalendarComponent = ({
  probabilityInfo,
  tableData,
  isLoading,
  isFetching
}) => {
  const { today, after3Days, tickValues, dayValues } = useGetTimeValues();
  const { handleMouseMove, handleMouseLeave, handleNodeClick, getNodeSize } =
    useChartInteraction();
  const conjunctionData = transformData(tableData);
  if (isLoading || isFetching) return <TableLoadingSpinner width={"30px"} height={"30px"} />;

  return (
    <Section>
      <ResponsiveScatterPlot
        data={conjunctionData}
        nodeSize={getNodeSize}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={!isMobile && handleNodeClick}
        colors={node => calculateColor(node.serieId, probabilityInfo)}
        tooltip={ConjunctionCalendarTooltip}
        margin={
          isMobile
            ? { top: 90, right: 20, bottom: 60, left: 90 }
            : { top: 110, right: 20, bottom: 60, left: 90 }
        }
        xScale={{
          type: "time",
          format: "%Y-%m-%d %H:%M:%S.%L",
          precision: "second",
          min: today,
          max: after3Days
        }}
        xFormat="time:%Y-%m-%d %H:%M:%S"
        axisTop={{
          format: "%H",
          tickValues: tickValues,
          tickSize: 6,
          legend: "TCA (UTC)",
          legendPosition: "middle",
          legendOffset: isMobile ? -70 : -95
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
          reverse: true
        }}
        yFormat=">-.3f"
        enableGridX={true}
        gridXValues={dayValues}
        enableGridY={true}
        gridYValues={[0]}
        axisLeft={{
          tickSize: 10,
          legend: "DCA (km)",
          legendPosition: "middle",
          legendOffset: -80
        }}
        blendMode="multiply"
        axisBottom={null}
        axisRight={null}
      />
      <DateLabel $ismobile={isMobile}>
        {dayValues.map((day, index) => {
          if (index !== dayValues.length - 1)
            return <div key={index}>{formatDate(day)}</div>;
        })}
      </DateLabel>
    </Section>
  );
};

export default ConjunctionCalendarComponent;

const Section = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
`;

const DateLabel = styled.div<{
  $ismobile: boolean;
}>`
  position: absolute;
  top: ${({ $ismobile }) => ($ismobile ? "40px" : "calc(100px - 3.5rem)")};
  left: 0;

  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  padding-left: 90px;
  padding-right: 20px;

  display: flex;
  align-items: center;
  > :nth-child(n) {
    background-color: #001326;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
    flex: 1;
  }
`;
