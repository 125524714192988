const OUR_TEAM = [
  {
    name: "Douglas Deok-Soo Kim, Ph.D.",
    role: "CEO & Founder",
    info: " Leading researcher of Voronoi diagrams for theory, algorithms and applications. He has introduced Voronoi diagram for saferand more efficient space.",
    img: "/team/Douglas_Kim.jpg"
  },
  {
    name: "Peter JH Ryu, Ph.D.",
    role: "CTO",
    info: "Leading mathematician for computational geometry, splines, optimization, statistics, etc. He always implements the core library.",
    img: "/team/Peter_Ryu.jpg"
  },
  {
    name: "Jaime JH Yoo",
    role: "CFO",
    info: "Certified Public Accountant & the principal partner of Sejung Accounting Firm.",
    img: "/team/Jaime.Yoo.jpg"
  },
  {
    name: "Shawn SH Choi",
    role: "CPO",
    info: "Leading manager for product development. Ph.D. candidate. He has the leadership for the product concept and architectures.",
    img: "/team/Shawn_Choi.jpg"
  },
  {
    name: "Martin YH Yoon",
    role: "COO",
    info: "Leading manager for Management Support. Bachelor of Business Administration and Law.",
    img: "/team/Martin_Yoon.jpg"
  },
  {
    name: "Alina Shymanska, Ph.D.",
    role: "Global Strategy",
    info: "",
    img: "/team/alina.shymanska.jpg"
  },
  {
    name: "Jayden MS Jeon",
    role: "Business Strategy",
    info: "",
    img: "/team/Jayden_Jeon.jpg"
  },
  {
    name: "Alan SG Lee",
    role: "Software Developement",
    info: "Powerful back-end developer. He graduated from Computer Science Department of Hanyang University Seoul.",
    img: "/team/Alan_Lee.jpg"
  }
];

export const ADVISORS = [
  {
    name: "Hee Yol Yu",
    role: "Senior Advisor",
    info: "Former Board Chairman of Korea Telecommunication (KT), Former Vice Minister of Ministry of Science and Technology, Korea. He has paved the cornerstones of Korean science and technology and contributed to the rapid growth of KT.",
    img: "/team/Hee-Yol-Yu.jpg"
  },
  {
    name: "Misoon Y. Mah, Ph.D.",
    role: "Senior Advisor",
    info: "President and CEO of M&K Research and Development Inc, Former AFRL Program Officer. She served at AFRL over 38 years.",
    img: "/team/Misoon-Y.-Mah,-Ph.D.jpg"
  },
  {
    name: "Dae Young Lee, Ph.D.",
    role: "Advisor, Orbit Mechanics",
    info: "Assistant Professor, Department of Aerospace Engineering, Iowa State University. He is one of leading experts in satellite attitude control.",
    img: "/team/Dae-Young-Lee,-Ph.D.jpg"
  }
];

export { OUR_TEAM };
