import { FAQ_URL, LOCALSTORAGE_KEY } from "@/constants/calendar";

const clickMenuType = (type, setCurrentMenu, navigate) => {
  if (type.label === "Contact SpaceMap") {
    localStorage.setItem(LOCALSTORAGE_KEY, "true");
    window.open(type.path, "_blank");
  } else if (type.label === "FAQ") {
    setCurrentMenu("faq");
    window.open(FAQ_URL, "_self");
  }
};

export default clickMenuType