import React from "react";
import { RESOURCE_LIST } from "@/constants/resources";
import {
  ResourceWrapper,
  Resource,
  ResourceSubtitle
} from "@/page/technology/resourcesStyle";
import DownloadSvg from "./DownloadSvg";
import ResourceHeader from "./ResourceHeader";

const ResourceList = () => {
  return (
    <ResourceWrapper>
      {RESOURCE_LIST.map((resource, index) => {
        return (
          <Resource key={`resource${index}`}>
            <ResourceHeader index={index} resource={resource} />
            <ResourceSubtitle>{resource.subtitle}</ResourceSubtitle>
            <DownloadSvg resource={resource} />
          </Resource>
        );
      })}
    </ResourceWrapper>
  );
};

export default ResourceList;
