import { useState, useEffect } from "react";
import { getFilePathById } from "./detailFormUtils";

const useDetailFiles = (id: string) => {
  const [flyer, setFlyer] = useState(null);

  const [document, setDocument] = useState(null);

  useEffect(() => {
    initFiles(id);
  }, [id]);

  const initFiles = async (id: string) => {
    try {
      const response = await getFilePathById(id);
      const { flyer, document } = response.keys;
      if (flyer) {
        const src = `${process.env.REACT_APP_CALENDAR_CDN_URL}/${flyer}`;
        setFlyer(src);
      }

      if (document) {
        const src = `${process.env.REACT_APP_CALENDAR_CDN_URL}/${document}`;
        setDocument(src);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    flyer,
    setFlyer,
    document,
    setDocument
  };
};

export { useDetailFiles }