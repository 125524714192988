import styled, { css } from "styled-components";
import ConjunctionCalendarComponent from "@/components/shared/conjunctionCalendar/ConjunctionCalendarComponent";
import GradientBar from "@/components/core/bar/GradientBar";
import { Layout } from "./conjunctionCalendarStyle";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import ConjunctionSelect from "@/components/core/select/ConjunctionSelect";
import { useConjunctionTable } from "@/components/astroOne/conjunctionTable/body/conjunctionTableHooks";
import { SearchType } from "@/components/astroOne/conjunctionTable/header/Search";
import CalendarTab from "@/components/calendar/CalendarTab";
import { useSearchParams } from "react-router-dom";
import {
  columns,
  headers
} from "@/components/astroOne/conjunctionTable/body/ConjunctionTable";
import Table from "@/components/ui/table/Table";
import { Button } from "@/page/calendar/spaceCalendarStyle";
import { SearchIcon } from "@/components/calendar/EventCalendar";

const ConjunctionCalendar = () => {
  const [probabilityInfo, setProbabilityInfo] = useState({
    max: 1,
    min: 0.000001
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarType = searchParams.get("view") || "calendar";
  const SELECT_TYPE = "CALENDAR";

  const {
    selected,
    setSelected,
    page,
    setPage,
    isLoading,
    isFetching,
    tableData
  } = useConjunctionTable({
    limit: 100,
    queryKey: SearchType.SEARCH
  });

  const CalendarComponent = (
    <CalendarWrapper $ismobile={isMobile}>
      <ConjunctionCalendarComponent
        probabilityInfo={probabilityInfo}
        tableData={tableData}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </CalendarWrapper>
  );

  return (
    <>
      <Layout $ismobile={isMobile}>
        <Utils>
          <CalendarTab />
          <Button onClick={() => {}}>
            Search
            <SearchIcon src="/svg/search-yellow.svg" />
          </Button>
        </Utils>
        <ConjunctionSelect
          selected={selected}
          setSelected={setSelected}
          type={SELECT_TYPE}
        />
        {calendarType === "calendar" ? (
          <>
            {isMobile ? (
              <CalendarViewer>{CalendarComponent}</CalendarViewer>
            ) : (
              <>{CalendarComponent}</>
            )}
            <GradientBarWrapper $ismobile={isMobile}>
              <GradientBar />
            </GradientBarWrapper>
          </>
        ) : (
          <ListWrapper $ismobile={isMobile}>
            <Table
              headers={headers}
              columns={columns}
              data={tableData}
              page={page}
              setPage={setPage}
              isLoading={isLoading || isFetching}
              totalPages={1}
              limit={100}
            />
          </ListWrapper>
        )}
      </Layout>
    </>
  );
};

export default ConjunctionCalendar;

const CalendarWrapper = styled.section<{ $ismobile: boolean }>`
  width: 100%;
  min-width: 700px;
  height: calc(100% - 3.6rem - 3rem);
  text {
    font-size: 1.3rem !important;
    font-weight: bold;
    @media screen and (max-width: 700px) {
      font-size: 1.5rem !important;
      height: calc(100% - 7rem);
    }
  }
  //tooltip position
  > :nth-child(1) {
    > :nth-child(1) {
      > :nth-child(1) {
        > :nth-child(2) {
          ${({ $ismobile }) =>
            $ismobile &&
            css`
              position: fixed !important;
              top: 8rem !important;
              left: 1rem !important;
              transform: none !important;
            `}
        }
      }
    }
  }
`;

const CalendarViewer = styled.section`
  width: calc(100vw - (100vw - 100%) / 2);
  height: 100%;
  overflow-x: scroll;
`;

const GradientBarWrapper = styled.section<{ $ismobile: boolean }>`
  position: relative;
  ${({ $ismobile }) =>
    !$ismobile &&
    css`
      margin-left: 90px;
      margin-right: 20px;
    `}
`;

const Utils = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListWrapper = styled.section<{ $ismobile: boolean }>`
  height: calc(100% - 3.6rem);
  overflow-y: auto;
  margin: 1rem 0;
  > :nth-child(1) {
    > :nth-child(2) {
      display: none;
    }
  }
  ${({ $ismobile }) =>
    $ismobile &&
    css`
      :nth-child(n) {
        font-size: 1rem !important;
      }
    `}
`;
