const RESOURCE_LIST = [
  {
    title: `Brief of SPACE$M$AP services`,
    subtitle:
      "Real-time Web Server for Safer, more Sustainable Space (AMOS, 2022)",
    link: "/resources/AMOS-Technical-Paper-SPACEMAP.pdf",
    type: "pdf"
  },
  {
    title: "Brief of SPACE$M$AP algorithm",
    subtitle:
      "Conjunction management for satellite constellations (AMOS, 2020)",
    link: "/resources/DOC_03_The_brief_of_SPACEMAP_algorithm_AMOS2020.pdf",
    type: "pdf"
  },
  {
    title: "Initial idea of SPACE$M$AP algorithm",
    subtitle:
      "Innovative Conjunction Prediction using Voronoi-filter (AMOS, 2017)",
    link: "/resources/DOC_02_The_initial_idea_of_SPACEMAP_algorithm_AMOS2017.pdf",
    type: "pdf"
  },
  {
    title: "Watcher-Catcher Service algorithm",
    subtitle:
      "A Real-time Function to Predict Spy Satellites in Timeline (IAC, 2022)",
    link: "/resources/IAC2022-WatcherCatcher.pdf",
    type: "pdf"
  },
  {
    title: "Introduction of Astro-$1$",
    subtitle: "Real-time Conjunction Assessment (KSAS, 2023)",
    link: "/resources/AstroFingertip.pdf",
    type: "pdf"
  },
  // {
  //   title: "Introduction of Astro$L$ibrary",
  //   subtitle: "Real-time Spatiotemporal Reasoning (KSAS, 2023)",
  //   link: "/resources/astrolibrary.pdf",
  //   type: "pdf"
  // },
  {
    title: "Introduction of Astro$L$ibrary",
    subtitle:
      "Real-time Conjunction Assessment and Optimal Collision Avoidance (JSSE, 2024)",
    link: "https://doi.org/10.1016/j.jsse.2024.07.003",
    type: "pdf"
  },
  {
    title: "Introduction to CD$M$ API",
    subtitle: "Step-by=step guide to using the CDM API endpoints",
    link: "https://www.youtube.com/watch?v=6TVuIKC3EVk",
    type: "video"
  },
  {
    title: "Introduction of 42$T$alks",
    subtitle: `A virtual meeting and collaboration platform`,
    link: "https://amostech.com/TechnicalPapers/2024/Conjunction-RPO/ChoiS.pdf",
    type: "pdf"
  },
  {
    title: "About Us",
    subtitle: `The Satellite's guide to the galaxy`,
    link: "/resources/SpaceMap-AboutUs.pdf",
    type: "pdf"
  }
];

export { RESOURCE_LIST };
