import { OPTION_LIST } from "@/constants/context";
import { useQueryGetConjunctions } from "@/query/conjunctions/useQueryCdm";
import { useEffect, useState } from "react";
import { convertTableData } from "./conjunctionTableUtils";

const useConjunctionTable = ({ limit, queryKey }) => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(OPTION_LIST[0].value);

  useEffect(() => {
    setPage(0);
  }, [selected]);

  const isGlobal = selected === OPTION_LIST[0].value;
  const isCountry =
    selected === OPTION_LIST[1].value ||
    selected === OPTION_LIST[2].value ||
    selected === OPTION_LIST[3].value;

  const { data, isLoading, isFetching } = useQueryGetConjunctions(
    {
      option: "simple",
      log: "www",
      limit,
      page,
      ...(isCountry
        ? { country: selected }
        : isGlobal
          ? {}
          : { primary: selected })
    },
    queryKey
  );

  const tableData = data ? convertTableData(data) : [];

  return {
    selected,
    setSelected,
    page,
    setPage,
    isLoading,
    isFetching,
    tableData
  };
};

export { useConjunctionTable }