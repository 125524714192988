import styled from "styled-components";
import {
  PageLayout,
  PageContainer,
  Title,
  TitleWrapper
} from "@/components/ui/PageTemplate";

 const NewsPageTemplate = styled(PageContainer)`
  width: ${props => `${props.$width}px`};
  min-width: 700px;
  height: auto;
  padding-top: 10rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
  justify-content: end;
`;

 const Layout = styled(PageLayout)`
  height: auto;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 2rem;
`;

 const NewsPageTitleWrapper = styled(TitleWrapper)`
  height: calc(100% - 40rem);
`;

 const NewsPageTitle = styled(Title)`
  color: #0070c0;
  font-size: 2.4rem;
  font-weight: 900;
  text-align: center;
`;

 const ArticleViewer = styled.section`
  width: calc(50vw + 67.125rem / 2);
  height: 36rem;
  overflow: hidden;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

 const Img = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    width: 4rem;
    height: 4rem;
  }
`;

 const ChevronUp = styled(Img)<{
  $index: number;
}>`
  bottom: 40rem;
  display: ${props => (props.$index === 0 ? "none" : "block")};
  @media screen and (max-width: 700px) {
    bottom: 41rem;
  }
`;

 const ChevronDown = styled(Img)<{
  $index: number;
  $last: number;
}>`
  bottom: 1rem;
  display: ${props => (props.$index === props.$last ? "none" : "block")};
`;

export { ChevronDown, ChevronUp, Img, ArticleViewer, NewsPageTemplate, NewsPageTitle, NewsPageTitleWrapper, Layout}