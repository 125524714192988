import styled from "styled-components";
import { isMobile } from "react-device-detect";
import EventEditForm from "@/components/calendar/eventEditForm/EventEditForm";
import { useSearchParams } from "react-router-dom";
import useCalendarStore from "@/store/calendarStore";
import TableLoadingSpinner from "@/components/ui/table/TableLoadingSpinner";
import withPasswordProtection from "@/components/calendar/WithPasswordProtection";

export const CalendarEditPage = () => {
  return (
    <EditLayout>
      <EditContent />
    </EditLayout>
  );
};

export default withPasswordProtection(CalendarEditPage);

const EditContent = () => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id") || "";
  const { isLoading, getEventById } = useCalendarStore();
  const { event, calendarType } = !isLoading && getEventById(id);

  if (isLoading) return <TableLoadingSpinner width={"3rem"} height={"3rem"} />;

  if (!event) return <div>No Event Found</div>;

  return <EventEditForm event={event} calendarType={calendarType} />;
};

const EditLayout = ({ children }) => {
  return <Layout $ismobile={isMobile}>{children}</Layout>;
};

const Layout = styled.section<{ $ismobile: boolean }>`
  position: relative;
  width: ${({ $ismobile }) => ($ismobile ? "100%" : "80%")};
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
`;
