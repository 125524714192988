const SNS_ITEMS = [
  {
    link: "https://www.linkedin.com/company/spacemap42",
    src: "/svg/linkedIn-white.svg",
    name: "LinkedIn"
  },
  {
    link: "https://twitter.com/SpaceMap42",
    src: "/svg/X-white.svg",
    name: "X"
  }
];

const EMAIL = "contact@spacemap42.com";

const ADDRESS =
  "#1103, ITBT, 222 Wangsimni-ro, Seongdong-gu, Seoul, Korea, 04763";

const LINKEDIN_URL =
  "https://www.linkedin.com/posts/briccdc_spacemap-pioneers-collision-avoidance-in-activity-7169102735517171713-6_29/?utm_source=share&utm_medium=member_desktop";

const PLATFORM_URL = "http://platform.spacemap42.com/";

export { SNS_ITEMS, EMAIL, ADDRESS, LINKEDIN_URL, PLATFORM_URL }