import { PageLayout } from "@/components/ui/PageTemplate";
import styled from "styled-components";

const Layout = styled(PageLayout)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0rem;
`;

const CalendarSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
`;

const CalendarWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  .fc-header-toolbar {
    display: flex;
    justify-content: space-between;
  }
  .fc-day-sun a {
    color: red;
    text-decoration: none;
  }
  .fc-day-sat a {
    color: blue;
    text-decoration: none;
  }
  .fc-event-title,
  .fc-event-time {
    color: #001326;
    font-weight: 400;
  }
  .fc-header-toolbar {
    height: 42px;
  }
  .fc-toolbar-title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
`;

const FilteredWrapper = styled.div`
  position: relative;
  width: 30rem;
  .fc-event {
    td {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
  }
`;

const CalendarHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddEventSection = styled.section`
  position: absolute;
  top: 0.7rem;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: end;
`;

const AddButton = styled.div`
  position: relative;
  top: 0;
  right: 0;
  padding: 0.3rem 0.5rem;

  border-radius: 5px;

  vertical-align: middle;
  font-size: 1rem;
  font-weight: 500;
  background-color: #001326;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #fccb16;
  }
`;

const EventInputSection = styled.section`
  position: relative;
  top: 0;
  right: 0;
  width: 20rem;

  z-index: 2;
  background-color: #ffffff;
  border: 1px solid lightgrey;
  border-radius: 10px;
`;

const Button = styled.div`
  position: relative;
  top: 0;
  right: 0;
  padding: 0.5rem 0.7rem;

  border-radius: 5px;

  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #001326;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }
`;

export { Button, EventInputSection, AddButton, AddEventSection, CalendarHeader, CalendarSection, CalendarWrapper, FilteredWrapper, Layout }