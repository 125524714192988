import { useState, useRef, useEffect } from "react";

import {
  fetchCalendarAccessToken,
  makeFileName,
  uploadFile,
} from "./eventFormUtils";

const useAddEvents = () => {
  const CALENDAR_ID = process.env.REACT_APP_GOOGLE_CALENDAR_ENROLL_ID;

  const [loading, setLoading] = useState(false);

  const addEvent = async event => {
    try {
      setLoading(true);
      const accessToken = await fetchCalendarAccessToken();
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(event)
        }
      );

      if (!response.ok) throw new Error(response.statusText);
      return response.json();
    } catch (error) {
      alert("Error creating event");
    } finally {
      setLoading(false);
    }
  };

  return { addEvent, loading };
};

const useFiles = () => {
  const flyerRef = useRef(null);
  const documentRef = useRef(null);
  const [flyer, setFlyer] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [document, setDocument] = useState(null);
  const uploadFlyer = () => {
    flyerRef.current.value = null;
    flyerRef.current && flyerRef.current.click();
  };

  const handleFlyerChange = event => {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        setPreviewUrl(reader.result);
      }
    };
    reader.readAsDataURL(selectedFile);
    setFlyer(selectedFile);
  };

  const uploadDocument = () => {
    documentRef.current.value = null;
    documentRef.current && documentRef.current.click();
  };

  const handleDocumentChange = event => {
    const selectedFile = event.target.files[0];
    setDocument(selectedFile);
  };

  const handleDeleteFlyer = e => {
    e.stopPropagation();
    setFlyer(null);
    setPreviewUrl(null);
    flyerRef.current.value = null;
  };

  const handleDocumentDelete = e => {
    e.stopPropagation();
    setDocument(null);
  };

  const handleUploadFile = async (key: string) => {
    let data = [];
    if (flyer) {
      const fileName = makeFileName(key, flyer.name);
      const fileKey = "space-calendar/flyer/" + fileName;
      const fileData = {
        key: fileKey,
        file: flyer
      };
      data.push(fileData);
    }
    if (document) {
      const fileName = makeFileName(key, document.name);
      const fileKey = "space-calendar/document/" + fileName;
      const fileData = {
        key: fileKey,
        file: document
      };
      data.push(fileData);
    }

    const responses = await Promise.all(
      data.map(fileData => uploadFile(fileData.key, fileData.file))
    );

    return responses;
  };

  return {
    flyerRef,
    documentRef,
    flyer,
    previewUrl,
    document,
    uploadFlyer,
    handleFlyerChange,
    uploadDocument,
    handleDocumentChange,
    handleDeleteFlyer,
    handleUploadFile,
    handleDocumentDelete
  };
};

const useSearchAddress = () => {
  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);
  // const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [englishAddress, setEnglishAddress] = useState("");
  const [koreanAddress, setKoreanAddress] = useState("");

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new window.google.maps.Map(
        document.createElement("div")
      );
    }
  }, [mapRef]);

  const onLoad = ref => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();

    if (places.length > 0) {
      const place = places[0];
      const { place_id } = place;
      getAddress(place_id, "en", setEnglishAddress);
      getAddress(place_id, "ko", setKoreanAddress);
    }
  };

  // url example: https://maps.googleapis.com/maps/api/place/details/json
  // ?fields=name%2Crating%2Cformatted_phone_number
  // &place_id=ChIJN1t_tDeuEmsRUsoyG83frY4
  // &key=YOUR_API_KEY
  const getAddress = async (placeId, language, callback) => {
    if (!mapRef.current) return;
    const service = new window.google.maps.places.PlacesService(mapRef.current);

    service.getDetails(
      {
        placeId,
        language
      },
      (place, status) => {
        if (status === "OK") {
          const { formatted_address, name } = place;
          const address = !!name
            ? `${name}, ${formatted_address}`
            : formatted_address;
          callback(address);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      }
    );
  };

  return {
    onLoad,
    onPlacesChanged,
    inputRef,
    address: {
      en: englishAddress,
      ko: koreanAddress
    }
  };
};

export { useAddEvents, useSearchAddress, useFiles };