import { isMobile } from "react-device-detect";

const INITIAL_VIEW = { calendar: "dayGridMonth", list: "listMonth" };
const HEADER_TOOLBAR = {
  calendar: {
    left: "timeGridWeek,dayGridMonth",
    center: "title",
    right: isMobile ? "prev,next" : "today prev,next"
  },
  list: {
    left: "",
    center: "title",
    right: isMobile ? "prev,next" : "today prev,next"
  }
};
const HEIGHT = {
  calendar: "100%",
  list: "100%"
};

const DESCRIPTION_FIELD = [
  "Host/Organization",
  "Host/Organization Contact",
  "Link",
  "Posting Author",
  "Posting Author Email",
  "Posting Author Phone",
  "Speakers",
  "Location(EN)",
  "Location(KR)",
  "Description"
];

const ACCESS_TOKEN = "accessToken";

const CALENDAR_TYPE = [
  { label: "Calendar", value: "calendar" },
  { label: "List", value: "list" }
];

const EVENT_CALENDAR_URL = "/spacecalendar/events";
const EVENT_SEARCH_URL = "/spacecalendar/events/search";
const EVENT_DETAIL_URL = "/spacecalendar/events/detail";
const EVENT_EDIT_URL = "/spacecalendar/events/edit";
const EVENT_ADD_URL = "/spacecalendar/events/add";
const CONJUNCTION_CALENDAR_URL = "/spacecalendar/conjunctions";
const FAQ_URL = "/spacecalendar/faq";

const LOCALSTORAGE_KEY = "goto-Contact";

const DESC_KEYS = {
  description: "Description",
  speakers: "Speakers",
  organization: "Host/Organization",
  organizationContact: "Host/Organization Contact",
  postingAuthor: "Posting Author",
  postingAuthorEmail: "Posting Author Email",
  postingAuthorPhone: "Posting Author Phone",
  location_en: "Location(EN)",
  location_kr: "Location(KR)",
  link: "Link"
};

const SELECT_TYPE = ["GLOBAL", "KOREA"];

const CALENDAR_ITEM_COLOR = {
  GLOBAL: "skyblue",
  KOREA: "#fccb16"
};

const CALENDAR_ID_MAP = {
  GLOBAL: process.env.REACT_APP_GOOGLE_CALENDAR_GLOBAL_ID,
  KOREA: process.env.REACT_APP_GOOGLE_CALENDAR_KOREA_ID
};

const LOGGING_TYPE = {
  eventShare: "spacecalendar_event_share",
  eventDetail: "spacecalendar_event_detail_view",
  eventAdd: "spacecalendar_event_add",
  eventEdit: "spacecalendar_event_edit",
  documentDownload: "spacecalendar_document_download",
  eventSearch: "spacecalendar_event_search",
  conjunctionClicked: "spacecalendar_conjunction_clicked"
};

export { LOGGING_TYPE, CALENDAR_ID_MAP, CALENDAR_ITEM_COLOR, 
  SELECT_TYPE, EVENT_ADD_URL, EVENT_CALENDAR_URL, EVENT_DETAIL_URL, 
  EVENT_EDIT_URL, EVENT_SEARCH_URL, DESC_KEYS, LOCALSTORAGE_KEY, 
  FAQ_URL, CONJUNCTION_CALENDAR_URL, CALENDAR_TYPE, ACCESS_TOKEN, 
  DESCRIPTION_FIELD, HEIGHT, HEADER_TOOLBAR, INITIAL_VIEW }