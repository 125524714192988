import EventCheckBox from "./EventCheckBox";
import { Button, CalendarHeader } from "@/page/calendar/spaceCalendarStyle";
import CalendarComponent from "./calendarComponent/CalendarComponent";
import styled, { css } from "styled-components";
import CalendarTab from "./CalendarTab";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EVENT_ADD_URL, EVENT_SEARCH_URL } from "@/constants/calendar";
import { isMobile } from "react-device-detect";
import { FaPlus } from "react-icons/fa";

const EVENT_BUTTONS = [
  { label: "Search", path: EVENT_SEARCH_URL },
  { label: "Add Event", path: EVENT_ADD_URL }
];

const EventCalendar = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarType = searchParams.get("view") || "calendar";

  const onBtnClick = path => {
    navigate(path);
  };

  return (
    <Layout $ismobile={isMobile}>
      <CalendarHeader>
        <CalendarTab />

        <ButtonSection>
          {EVENT_BUTTONS.map((btn, index) => {
            return (
              <Button key={index} onClick={() => onBtnClick(btn.path)}>
                {btn.label}
                {btn.label === "Search" ? (
                  <SearchIcon src="/svg/search-yellow.svg" />
                ) : (
                  <FaPlus style={{ color: "#fccb16" }} />
                )}
              </Button>
            );
          })}
        </ButtonSection>
      </CalendarHeader>
      <HStack>
        <EventCheckBox />
        <Description $ismobile={isMobile}>
          Space event can be added and/or searched in this calendar.
        </Description>
      </HStack>

      <CalendarSection type={calendarType}>
        <CalendarComponent type={calendarType} />
      </CalendarSection>
    </Layout>
  );
};

export default EventCalendar;

const Layout = styled.section<{ $ismobile: boolean }>`
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 4rem 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  ${({ $ismobile }) =>
    $ismobile &&
    css`
      width: 90%;
      padding-top: 2rem;
    `}

  @media screen and (max-width: 700px) {
    gap: 0.5rem;
  }
`;

const Description = styled.span<{ $ismobile: boolean }>`
  position: relative;
  line-height: 1;
  text-align: center;
  color: #001326;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: ${({ $ismobile }) => ($ismobile ? "0.8rem" : "1rem")};
  font-weight: 500;
  flex: 1;
`;

const CalendarSection = styled.section<{ type: string }>`
  position: relative;
  height: 100%;
  .fc-header-toolbar {
    display: flex;
    justify-content: space-between;
  }
  .fc-dayGridMonth-view {
    .fc-day-sun a {
      color: red;
      text-decoration: none;
    }
    .fc-day-sat a {
      color: blue;
      text-decoration: none;
    }
    .fc-col-header-cell {
      background-color: #d1d1d14c;
    }
  }
  .fc-event-title,
  .fc-event-time {
    color: #001326;
    font-weight: 400;
    padding-left: 5px;
  }
  .fc-header-toolbar {
    height: 42px;
  }
  .fc-toolbar-title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
      transform: translate(-50%, 30%);
    }
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5rem;
  }
  .fc-list-day-cushion {
    color: #000000;
    .fc-list-day {
      color: #000000;
    }
  }
  .fc-toolbar-title,
  .fc-button {
    text-transform: capitalize;
  }
  .fc-event {
    cursor: pointer;
  }
`;

const ButtonSection = styled.section`
  width: 15rem;
  height: 2rem;

  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const SearchIcon = styled.img`
  width: 1.3rem;
  height: 1.3rem;
`;

const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
