import { useEffect } from "react";

const useObserver = id => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const target = entry.target as HTMLElement;
          if (entry.isIntersecting) {
            target.style.transition = "opacity 1s";
            target.style.opacity = "1";
          } else {
            target.style.opacity = "0";
          }
        });
      },
      {
        root: null,
        rootMargin: "-300px 0px -300px 0px"
      }
    );
    const target = document.getElementById(`${id}`);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        target.style.transition = "opacity 1s";
        target.style.opacity = "0";
        observer.unobserve(target);
      }
    };
  }, [id]);
};

export default useObserver;
