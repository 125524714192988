import { SELECT_TYPE } from "@/constants/calendar";
import { create } from "zustand";

import * as _ from "lodash";
import dayjs from "dayjs";
import { isSimilar } from "@/utils";

export type CalendarTime = {
  // all day
  date?: string;

  // specific time
  dateTime?: string;
  timeZone?: string;
};

export interface CalendarEvent {
  id: string;
  summary: string;
  location: string;
  start: CalendarTime;
  end: CalendarTime;
  description: {
    link: string;
    description: string;
    location_en: string;
    location_kr: string;
    postingAuthor: string;
    postingAuthorEmail: string;
    postingAuthorPhone: string;
    speakers: string[];
    organization: string;
    organizationContact: string;
  };

  backgroundColor: string;
  borderColor: string;
}

interface CalendarStore {
  timezone: string;
  events: {
    [key: string]: CalendarEvent[];
  };
  checkedEvent: {
    [key: string]: boolean;
  };
  isLoading: boolean;
  setTimezone: (timezone: string) => void;
  setEvents: (events: CalendarEvent[], type: string) => void;
  setCheckedEvent: (checkedEvent: { [key: string]: boolean }) => void;
  setCheckedAllTrue: () => void;
  setIsLoading: (isLoading: boolean) => void;
  getSearchResults: (keyword: string) => any[];
  getEventById: (id: string) => {
    event: CalendarEvent;
    calendarType: string;
  };
  searchEvents: (
    params: {
      keyword: string;
      startDate: string;
      endDate: string;
    },
    checkedEvent: {
      [key: string]: boolean;
    }
  ) => CalendarEvent[];
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const initialData = {
  timezone: timeZone,
  events: {
    ...SELECT_TYPE.reduce((acc, type) => {
      acc[type] = [];
      return acc;
    }, {})
  },
  checkedEvent: {
    ...SELECT_TYPE.reduce((acc, type) => {
      if (type === "GLOBAL") {
        acc[type] = true;
      } else if (type === "KOREA") {
        acc[type] = timeZone === "Asia/Seoul" ? true : false;
      } else {
        acc[type] = false;
      }

      return acc;
    }, {})
  },
  isLoading: true
};

const useCalendarStore = create<CalendarStore>((set, get) => ({
  ...initialData,
  setTimezone: timezone => {
    set({ timezone });
  },
  setEvents: (events, type) =>
    set(prev => {
      let _events = _.cloneDeep(prev.events);
      _events[type] = events;

      return {
        ...prev,
        events: _events
      };
    }),
  setCheckedEvent: checkedEvent => set({ checkedEvent }),
  setCheckedAllTrue: () => {
    set({ checkedEvent: { [SELECT_TYPE[0]]: true, [SELECT_TYPE[1]]: true } });
  },
  setIsLoading: isLoading => set({ isLoading }),
  getSearchResults: keyword => {
    return [];
  },
  getEventById: id => {
    let event, calendarType;
    const { events } = get();
    Object.entries(events).forEach(([type, events]) => {
      if (event) return;
      event = events.find(e => e.id === id);
      if (event) calendarType = type;
    });

    return { event, calendarType };
  },
  searchEvents(params, checkedEvent) {
    if (!params) return [];

    const { events } = get();

    const threshold = 0.8; // 유사도 임계값, 필요에 따라 조정 가능

    const searchResults = Object.entries(events).reduce(
      (acc, [type, events]) => {
        if (!checkedEvent[type]) return acc;

        return acc.concat(
          events.filter(event => {
            let { keyword, startDate, endDate } = params;

            (startDate as any) = startDate ? dayjs(startDate) : dayjs();

            const eventStartDate = event.start.date || event.start.dateTime;
            if (dayjs(eventStartDate).isBefore(dayjs(startDate))) return false;

            if (endDate) {
              const eventEndDate = event.end.date || event.end.dateTime;
              if (dayjs(eventEndDate).isAfter(dayjs(endDate))) return false;
            }

            const searchTarget = Object.values(event)
              .filter(value => !!value)
              .map(value => {
                if (Array.isArray(value)) {
                  return value.join(" ");
                }
                if (value instanceof dayjs) {
                  return (value as Dayjs).toISOString();
                }
                return String(value);
              })
              .join(" ");

            const tokens = searchTarget.toLowerCase().split(/\s+/);
            const keywordTokens = keyword.toLowerCase().split(/\s+/);

            return keywordTokens.some(keywordToken =>
              tokens.some(
                token =>
                  token.includes(keywordToken) ||
                  isSimilar(token, keywordToken, threshold)
              )
            );
          })
        );
      },
      []
    );

    const sortedSearchResults = searchResults.sort((a, b) => {
      const aDate = a.start.date || a.start.dateTime;
      const bDate = b.start.date || b.start.dateTime;

      return dayjs(aDate).isBefore(dayjs(bDate)) ? -1 : 1;
    });

    return sortedSearchResults;
  }
}));

export default useCalendarStore;
