import styled from "styled-components";
import { PageLayout } from "@/components/ui/PageTemplate";

const Layout = styled(PageLayout)`
  justify-content: space-between;
  width: 90rem;
`;

const ImgWrapper = styled.section`
  width: 40rem;
  height: 30rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 40rem;
`;

const DescriptionWrapper = styled.section`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  gap: 1rem;
`;

const MainDescription = styled.span`
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  text-align: start;
`;

const SubDescription = styled.span`
  font-size: 1.2rem;
  font-weight: 300;
  color: #fff;
`;

export { Layout, ImgWrapper, Img, DescriptionWrapper, MainDescription, SubDescription }