import {
  TeamItem,
  ImgWrapper,
  Img,
  Info,
  Name,
  Role
} from "@/page/company/teamStyle";
import { OUR_TEAM } from "@/constants/memberList";

const OurTeam = () => {
  return (
    <>
      {OUR_TEAM.map((member, index) => {
        return (
          <TeamItem key={`team${index}`}>
            <ImgWrapper>
              <Img src={member.img} alt="member.jpg" />
            </ImgWrapper>
            <Info>
              <Name>{member.name}</Name>
              <Role>{member.role}</Role>
            </Info>
          </TeamItem>
        );
      })}
    </>
  );
};

export default OurTeam;
