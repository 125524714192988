import React from 'react';
import './privacyPolicy.css';  // Assuming you have a CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header className="header">
        <h1 className="logo">SPACEMAP</h1>
        <h2 className="privacy-title">PRIVACY POLICY</h2>
      </header>

      <div className="update-info">
        <p>This Privacy Policy was last updated on May 27, 2024. See previous versions of the Privacy Policy in our Policy Archives.</p>
        <p>The highlighted text is aimed to give a plain English summary of our Privacy Policy. Please ensure you read the main text, as the plain English summary is just a summary and doesn’t capture all of the terms.</p>
      </div>

      <section className="policy-section">
        <h3 className="section-title">What does this policy cover</h3>
        <p>Welcome to SPACEMAP, the online and mobile service of SPACEMAP Inc (“we,” or “us”). Our Privacy Policy explains how we and our affiliates collect, use, disclose, and protect information that applies to our satellite collision and collision avoidance data service (the "Service"), and your choices about the collection and use of your information. Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in our Terms of Use. If you do not want your information processed in accordance with this Privacy Policy in general or any part of it, you should not use our Service. This policy applies to all users of the Service.</p>
        <p className="summary">Summary: Welcome, here is our policy on privacy. This policy sets out how we collect and use the information that we collect about you when you use the Service. This policy also explains the choices that you can make about the way that we use your information.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">1. Information we collect</h3>

        <h4>(a) Information you provide us directly</h4>
        <p>We may ask for certain information when you register for an account or correspond with us (such as your name, email address, phone number, payment information, and contact information).</p>
        <p>We also collect any messages you send us through the Service and may collect information you provide in User Content you post to the Service. We use this information to operate, maintain, improve and provide the features and functionality of the Service to you.</p>
        <p>If you don’t provide your personal information to us, you may not be able to access or use our Service or your experience of using our Service may not be as enjoyable.</p>
        <p className="summary">Summary: We collect info about you that you choose to give us, for example when you register an account, use the Service, or otherwise interact with us.</p>

        <h4>(b) Information we receive from third-party applications</h4>
        <p>We may receive information about you from third parties. For example, if you access the Service through a third-party connection or log-in, such as social media accounts, that third party may pass certain information about your use of its service to us. This information could include, but is not limited to, user ID, access token, and any information you have permitted the third party to share with us.</p>
        <p className="summary">Summary: When you use our Service, for example, if you log in through a third-party application, we may obtain information about you from such third-party application.</p>

        <h4>(c) Information we receive from other third parties</h4>
        <p>We may obtain information about you from third-party sources, such as public sources, social media platforms, and third-party data providers. This information could include your company, job title, industry, and other profile information. We use this information to better understand your profile and interests, so we can deliver customized offers and other personalized services to you.</p>
        <p className="summary">Summary: We may obtain information about you from third-party sources to understand your interests and personalize our communications and promotions to you. You can opt out at any time.</p>

        <h4>(d) Information we collect from you automatically</h4>
        <p>We directly collect or generate certain information about your use of the Service (such as user activity data, analytics event data, and clickstream data) for data analytics and to help us measure traffic and usage trends for the Service. We may also use third-party analytics tools that automatically collect information sent by your browser or mobile device.</p>
        <p className="summary">Summary: We collect and generate certain info about how you use our Service automatically. This helps us to provide and improve the Service for you.</p>

        <h4>(e) Cookies information and information taken from similar technologies</h4>
        <p>When you visit the Service, we (and our third-party partners) will send cookies to your computer that uniquely identify your browser and let us do things like help you log in faster, enhance your navigation through the site, and remember your preferences. For more information on how we use cookies and other technologies and how you can control them, please read our Cookies Policy.</p>
        <p className="summary">Summary: We use cookies to help you use our Service and for other business purposes. For more information about how we use cookies and how you can control them, please see our Cookies Policy.</p>

        <h4>(f) Log file information</h4>
        <p>Log file information is automatically reported by your browser or mobile device each time you access the Service. When you use our Service, our servers automatically record certain log file information. These server logs may include anonymous information such as your web request, browser type, referring/exit pages and URLs, number of clicks, and how you interact with links on the Service.</p>
        <p className="summary">Summary: Whenever you load a page from our Service, your browser sends us info about itself and your interactions with our Service. That info gets stored on our servers.</p>

        <h4>(g) Clear gifs/web beacons information</h4>
        <p>When you use the Service, we may employ clear GIFs (also known as web beacons) which are used to anonymously track the online usage patterns of our users. We may also use clear GIFs in HTML-based emails sent to our users to track which emails are opened and which links are clicked by recipients.</p>
        <p className="summary">Summary: We might use small images in order to check how many people open our emails and visit our site.</p>

        <h4>(h) Device identifiers</h4>
        <p>When you access the Service on a device (including smartphones or tablets), we may access, collect, and/or monitor one or more “device identifiers,” such as a universally unique identifier (“UUID”). Device identifiers are small data files that uniquely identify your mobile device. Some features of the Service may not function properly if the use of device identifiers is impaired.</p>
        <p className="summary">Summary: Your phone or device sends us information about your usage.</p>

        <h4>(i) Location data</h4>
        <p>We collect information to understand where our users are located for a number of reasons, such as to localize and personalize content and comply with local laws.</p>
        <p className="summary">Summary: We may collect and use your location data for personalization, analytics, advertising, and tax purposes.</p>

        <h4>(j) Content within your account</h4>
        <p>We receive content that you create within your account and media you upload for use within it, such as designs, images, documents, videos, and metadata about your content.</p>
        <p className="summary">Summary: We collect the content you upload to your account.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">2. How we use your information</h3>
        <p>We use the information we collect about you for the purposes set out below:</p>
        <ul>
          <li>Providing you with the Service</li>
          <li>For data analytics</li>
          <li>For Service improvement</li>
          <li>Customizing the Service for you</li>
          <li>To communicate with you about the Service</li>
          <li>To promote and drive engagement with the Service</li>
          <li>For advertising purposes</li>
          <li>Customer happiness</li>
          <li>For safety, security, fraud, and abuse measures</li>
          <li>For matters that you have specifically consented to</li>
          <li>For troubleshooting, error resolution, and service improvement</li>
          <li>For matters that we are required to use your information by law</li>
        </ul>
        <p className="summary">Summary: We use information about you for different reasons, including to provide, customize and improve the Service.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">3. Sharing your information</h3>

        <h4>(a) How we share your information</h4>
        <p>We share your information with third-party service providers for the purpose of providing the Service to you, to facilitate our legitimate interests, or if you consent. These service providers are vetted by us and will only be provided with access to your information as is reasonably necessary for the purpose that we have engaged the service provider.</p>
        <p className="summary">Summary: We might share some information about you with our business partners and third-party service providers in order to provide the Service to you or to fulfill our legitimate business interests.</p>

        <h4>(b) How you can share your User Content</h4>
        <p><strong>(i) Public Posts:</strong> Any information or content that you voluntarily disclose for posting to the Service becomes available to be read, collected, and used by the public. You may share your designs to your social media accounts. You should ensure that you familiarize yourself with the privacy policies of each of these services.</p>

        <p><strong>(ii) Teams:</strong> If you are part of a Team, any information or content you create in that Team may be shared with other Team members and the Team Owner.</p>

        <p><strong>(iii) Design Collaboration:</strong> If you accept an invitation to view or edit a design, your name and avatar will be visible to anyone else in the design at the same time as you, whether or not they are logged in to the Service or anonymous.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">4. Advertising</h3>
        <p>We partner with third-party ad servers, ad networks, and social media platforms to deliver personalized advertisements (“ads”) on our Service and other sites that may be of interest to you and/or to measure their effectiveness, and/or to identify potential new users of our Service.</p>
        <p>We may share certain information with our third-party advertising partners, such as your email address, location, cookie information, and information relating to your use of our Service, and allow partners to perform a match of your information against information from other third-party networks or sites to serve ads either on the Service or on third-party sites and to measure the effectiveness of these ads.</p>
        <p>Please note that an advertiser may ask us to show an ad on the Service to a certain audience of users (e.g., based on demographics or other interests). In that situation, we determine the target audience and serve the advertising to that audience and only provide anonymous aggregated data to the advertiser.</p>
        <p>The Privacy Policy does not apply to, and we cannot control the activities of, third-party advertisers. Please consult the respective privacy policies of such advertisers or contact such advertisers for more information.</p>
        <p className="summary">Summary: If we have ads on our site, we might use some of your info to show you appropriate ads. We may also use some of that info to send you relevant ads on other sites, like Facebook or Google.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">5. How we transfer, store and protect your data</h3>
        <p>Your information collected through the Service will be stored and processed in the United States, Australia, and any other country in which we or our subsidiaries, affiliates, or service providers maintain facilities or employ staff or contractors. We may transfer information that we collect about you, including personal information, to affiliated entities, and to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world.</p>
        <p>As a result, we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction. However, we always take steps to ensure that your information remains protected wherever it is stored and processed in accordance with applicable laws.</p>
        <p className="summary">Summary: To run our Service, we’ll have to use our group members and vendors around the world. This means your information might be transferred to the U.S., Australia, and anywhere else the Service is operated.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">6. Keeping your information safe</h3>
        <p>We care about the security of your information, and use appropriate safeguards to preserve the integrity and security of all information collected through the Service. To protect your privacy and security, we take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to your email communications from us, at all times.</p>
        <p>However, we cannot ensure or warrant the security of any information you transmit to us or guarantee that information on the Service may not be accessed, disclosed, altered, or destroyed. Your privacy settings may also be affected by changes to the functionality of third-party sites and services that you add to the Service, such as social networks. We are not responsible for the functionality or security measures of any third party.</p>
        <p className="summary">Summary: We care about the safety of your data and have implemented industry-recognized measures to protect it, but unfortunately, we can’t guarantee that nothing bad will ever happen to it.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">7. Your choices about your information</h3>

        <h4>(a) You control your account information and settings</h4>
        <p>We provide choices about how we process your account information:</p>
        <ul>
          <li>You can manage the privacy preferences available to you by visiting the privacy settings page and updating your preferences at any time;</li>
          <li>You can request access, correction or deletion of the data we hold on you by contacting contact@spacemap42.com; and</li>
          <li>You can opt out of receiving marketing messages in the “Message Preferences” section of Your Account settings or by clicking on the “unsubscribe link” provided in such communications. However, you may not opt out of Service-related communications (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices).</li>
        </ul>
        <p className="summary">Summary: You have control over your account settings, such as your account information and marketing email notifications, but there’s some important stuff we’ll always send you. If you have any questions about reviewing or modifying your account information, you can contact us directly at contact@spacemap42.com.</p>

        <h4>(b) Opting out of collection of your information through tracking technologies</h4>
        <p>Please refer to your mobile device or browser’s technical information for instructions on how to delete and disable cookies, and other tracking/recording tools. Note that disabling cookies and/or other tracking tools prevents us or our business partners from tracking your browser’s activities in relation to the Service, and for use in targeted advertising activities by us, including via third-party websites. However, doing so may disable many of the features available through the Service.</p>
        <p className="summary">Summary: If you want us to stop collecting information about you, there may be some settings you can adjust in your browser or device. But our Service might not be personalized for you without it, making it harder to find what you're looking for.</p>

        <h4>(c) Opting out of enrichment using third-party data</h4>
        <p>As described above, we may combine information about you with information obtained from third-party sources to better understand your profile and interests so that we can deliver customized offers and other personalized services. You can opt out of us collecting more data about you from third-party providers to tailor your experience and messages we send you here or by emailingcontact@ spacemap42.com.</p>
        <p className="summary">Summary: You can opt out of receiving promotions based on information we learn about you from third parties, for example, your job title and employer.</p>

        <h4>(d) Managing how we analyze your data for analytics and machine learning</h4>
        <p>You can manage your preferences relating to how we and the third-party service providers we engage to enable functionality within the Service, may use your data for training AI, including through the use of machine learning technologies.</p>
        <p className="summary">Summary: You have control over whether we analyze your data to build and improve AI features.</p>

        <h4>(e) Rights in respect of your Information</h4>
        <p>The laws of some countries grant particular rights in respect of personal information. Individuals in certain countries, including the European Union, United Kingdom, and Brazil have the right to:</p>
        <ul>
          <li>Request access to their information;</li>
          <li>Request that we correct inaccuracies in their information;</li>
          <li>Request that their information be deleted or that we restrict access to it;</li>
          <li>Request a structured electronic version of their information; and</li>
          <li>Object to our use of their information.</li>
        </ul>
        <p>Should you wish to make a request in respect of your personal information please contact us at contact@spacemap42.com. You also have the right to object to our processing of personal data about you for marketing purposes and we will stop processing data for that purpose.</p>
        <p className="summary">Summary: You may have specific rights in relation to your information depending on where you live. We provide you with a number of controls in Your Account settings and you can access your privacy rights by emailing contact@spacemap42.com.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">8. How long we keep your information</h3>
        <p>Following termination or deactivation of your user account, we will retain your profile information and User Content for a commercially reasonable time, and for as long as we have a valid purpose to do so. In particular, we will retain your information for the purpose of complying with our legal and audit obligations, and for backup and archival purposes.</p>
        <p className="summary">Summary: We retain your profile information and user content for the purpose of providing our Service to you and to comply with our legal and regulatory obligations.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">9. Links to other websites and services</h3>
        <p>We are not responsible for the practices employed by websites or services linked to or from the Service, including the information or content contained therein. Please remember that when you use a link to go from the Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link or advertisement on our website, are subject to that third party’s own rules and policies. In addition, you acknowledge that we are not responsible for and do not exercise control over any third parties that you authorize to access your User Content. If you are using a third-party website or service and you allow such a third party access to your User Content you do so at your own risk.</p>
        <p className="summary">Summary: If we post a link to a third party website, we can’t control what happens on the other end. The same applies if you let another site use your data on our Service; the use of your information will be governed by the Privacy Policy of the third party.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">10. Changes to this Policy</h3>
        <p>We may update this policy from time to time to reflect our current practice and ensure compliance with applicable laws. When we post changes to this policy, we will revise the “Last Updated” date at the top of this policy. If we make any material changes to the way we collect, use, store and/or share your personal information, we will notify you on our website or by sending an email to the email address associated with your account. We recommend that you check this page from time to time to inform yourself of any changes.</p>
        <p className="summary">Summary: We won’t make any major changes to our Privacy Policy without giving notice – but it’s still a good idea to visit this page every now and then.</p>
      </section>

      <section className="policy-section">
        <h3 className="section-title">11. How to contact us</h3>
        <p>If you have any questions about this Privacy Policy or the Service, or wish to make a complaint please contact us at:</p>
        <p>Email: <a href="mailto:contact@spacemap42.com">contact@spacemap42.com</a></p>
        <p>Write to us at:</p>
        <p>Head of Privacy<br />
          SPACEMAP Inc<br />
          222 Wangsimni-Ro<br />
          Seongdong-gu <br />
          Seoul, 04763 <br />
          South Korea.<br /></p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
