import React from "react";
import PageTemplate, {
  TitleWrapper,
  Description
} from "@/components/ui/PageTemplate";
import {
  Layout,
  Header,
  Container,
  DescriptionWrapper
} from "./resourcesStyle";
import { RESOURCE_CONTEXT } from "@/constants/context";
import ResourceList from "@/components/resource/ResourceList";
import useObserver from "@/hooks/page/useObserver";

const Resources = () => {
  useObserver("content6");

  return (
    <PageTemplate id="Resources">
      <Layout id="content6">
        <TitleWrapper>
          <Header> {RESOURCE_CONTEXT.TITLE} </Header>
        </TitleWrapper>
        <Container>
          <DescriptionWrapper>
            {RESOURCE_CONTEXT.DESCRIPTION.map((item, index) => {
              return <Description key={index}>{item}</Description>;
            })}
          </DescriptionWrapper>
          <ResourceList />
        </Container>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Resources);
