import SpaceCalendarLogo from "@/components/shared/logo/SpaceCalendarLogo";
import {
  CONJUNCTION_CALENDAR_URL,
  EVENT_CALENDAR_URL,
  FAQ_URL
} from "@/constants/calendar";
import useInitCalendarData from "@/hooks/calendar/useCalendarData";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import clickMenuType from "./menuUtils";

const CALENDAR_TYPE = [
  { label: "Event Calendar", key: "events", path: EVENT_CALENDAR_URL },
  {
    label: "Conjunction Calendar",
    key: "conjunctions",
    path: CONJUNCTION_CALENDAR_URL
  }
];

const MENU_TYPE = [
  { label: "FAQ", key: "faq", path: FAQ_URL },
  { label: "Contact SpaceMap", key: "contact", path: "/" }
];

const PRODUCT_TYPE = [
  { label: "Home", url: "/" },
  { label: "Platform", url: "https://platform.spacemap42.com/" }
];

const Menu = ({ isMenuOpen = undefined, setIsMenuOpen = undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentMenu, setCurrentMenu] = useState(
    location.pathname.split("/")[2]
  );
  useInitCalendarData();

  return (
    <>
      <Section $ismobile={isMobile} $isopen={isMenuOpen}>
        {isMobile && (
          <MdKeyboardArrowLeft
            size={32}
            color="#ffffff"
            style={{
              position: "absolute",
              right: "1rem",
              zIndex: "2"
            }}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          />
        )}
        <Wrapper>
          <SpaceCalendarLogo />
          <Divider $ismobile={isMobile} />
          <TypeSection>
            {CALENDAR_TYPE.map((type, index) => {
              return (
                <CalendarType
                  key={index}
                  onClick={() => {
                    navigate(type.path);
                    setCurrentMenu(type.key);
                  }}
                  $current_menu={currentMenu}
                  type={type.key}
                  $ismobile={isMobile}
                >
                  {type.label}
                </CalendarType>
              );
            })}
          </TypeSection>
          <Divider $ismobile={isMobile} />
          <TypeSection>
            {MENU_TYPE.map((type, index) => {
              return (
                <MenuType
                  key={index}
                  $current_menu={currentMenu}
                  type={type.key}
                  $ismobile={isMobile}
                  onClick={() => clickMenuType(type, setCurrentMenu, navigate)}
                >
                  {type.label}
                </MenuType>
              );
            })}
          </TypeSection>
          <ProductSection>
            {PRODUCT_TYPE.map((type, index) => {
              return (
                <Product
                  key={index}
                  onClick={() =>
                    window.open(
                      type.url,
                      type.label === "Platform" ? "_blank" : "_self"
                    )
                  }
                  $ismobile={isMobile}
                >
                  {type.label}
                </Product>
              );
            })}
          </ProductSection>
        </Wrapper>
      </Section>
    </>
  );
};

export default Menu;

const Section = styled.section<{ $ismobile: boolean; $isopen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;

  box-sizing: border-box;
  width: 20rem;
  height: 100%;
  padding: 1rem 2rem;

  box-shadow: 10px 0px 40px rgba(0, 0, 0, 0.6);
  background-color: #001326;
  z-index: 10;

  ${({ $ismobile, $isopen }) =>
    $ismobile &&
    css`
      width: 70%;
      padding-top: 3rem;
      box-shadow: none;
      transition: transform 0.5s ease-in-out;
      transform: translateX(${$isopen ? 0 : -100}%);
      :nth-child(n) {
        font-size: 1.8rem;
      }
    `}
`;

const Wrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TypeSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductSection = styled.section`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  align-items: center;
`;

const Product = styled.div<{
  $ismobile: boolean;
}>`
  width: 100%;
  padding: 0.75rem 0;

  text-align: center;
  font-size: 1.2rem;
  color: #fccb16;

  cursor: pointer;

  ${({ $ismobile }) =>
    $ismobile &&
    css`
      padding: 1.5rem 0;
    `}
`;

const CalendarType = styled.div<{
  $current_menu: string;
  type: string;
  $ismobile: boolean;
}>`
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 0.75rem 0;
  ${({ $current_menu, type }) =>
    $current_menu === type &&
    css`
      background-color: #fccb16;
      color: #001326;
    `}
  ${({ $ismobile }) =>
    $ismobile &&
    css`
      padding: 1.5rem 0;
    `}
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
`;

const MenuType = styled(CalendarType)`
  color: #fccb16;
  ${({ $current_menu, type }) =>
    $current_menu === type &&
    css`
      background-color: #fccb16;
      color: #001326;
    `}
`;

const Divider = styled.div<{ $ismobile: boolean }>`
  width: 100%;
  border-top: 1px solid #ffffff;
  margin: ${({ $ismobile }) => ($ismobile ? "2rem 0" : "1rem 0")};
`;
