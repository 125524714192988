import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { useChartInteraction } from "@/components/shared/conjunctionCalendar/conjunctionCalendarComponentHooks";
import { FaPlay } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const ConjunctionCalendarTooltip = ({ node }) => {
  const { handleNodeClick } = useChartInteraction();

  const ITEMS = [
    `Primary: ${node.data.pName} (${node.data.pId})`,
    `Secondary: ${node.data.sName} (${node.data.sId})`,
    `TCA (UTC): ${node.formattedX}`,
    `DCA (km): ${node.formattedY}`,
    `Collision Probability: ${node.data.poc.toExponential(2)}`
  ];
  console.log(node)
  return (
    <Tooltip $ismobile={isMobile}>
      {isMobile && (
        <IoClose
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            pointerEvents: "all"
          }}
          size={20}
        />
      )}
      {ITEMS.map((item, index) => {
        return <Text key={index}>{item}</Text>;
      })}
      {isMobile ? (
        <VisBtn onClick={() => handleNodeClick(node)}>
          <FaPlay color="#001326" />
          Visualization on Platform
        </VisBtn>
      ) : (
        <VisualMessage>Click the point to visualize</VisualMessage>
      )}
    </Tooltip>
  );
};

export default ConjunctionCalendarTooltip;

const Tooltip = styled.div<{ $ismobile: boolean }>`
  background: #001326;
  color: #ffffff;
  padding: 9px 12px;
  ${({ $ismobile }) =>
    $ismobile &&
    css`
      padding: 3rem;
      padding-bottom: 2rem;
      :nth-child(n) {
        font-size: 1.5rem;
      }
    `}
  border: 1px solid #001326;
  border-radius: 1rem;
`;

const Text = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

const VisualMessage = styled(Text)`
  color: #fccb16;
`;

const VisBtn = styled.div`
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
  padding: 0.3rem 0.3rem;

  color: #001326;
  background-color: #fccb16;
  border-radius: 2rem;

  pointer-events: all !important;
  cursor: pointer;
`;
