import "./App.css";
import { isMobile } from "react-device-detect";
import NavigationBar from "./components/navigationBar/NavigationBar";
import MobileNavBar from "./components/navigationBar/MobileNavBar";
import MobileBody from "./page/MobileBody";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./page/privacy/PrivacyPolicy";
import SpaceCalendar from "./page/calendar/SpaceCalendar";
import NewBody from "./page/NewBody";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isMobile ? (
                <>
                  <MobileNavBar />
                  <MobileBody />
                </>
              ) : (
                <>
                  <NavigationBar />
                  <NewBody key="newBody" />
                </>
              )
            }
          />
          <Route path="/spacecalendar/*" element={<SpaceCalendar />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
