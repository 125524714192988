import React, { useEffect, useMemo, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import {
  EVENT_DETAIL_URL,
  HEADER_TOOLBAR,
  HEIGHT,
  INITIAL_VIEW,
} from "@/constants/calendar";
import useCalendarStore from "@/store/calendarStore";
import TableLoadingSpinner from "../../ui/table/TableLoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useDateParams } from "./calendarComponentHooks";
import { CalendarEvent } from "@/store/calendarStore";

interface CalendarEventViewProps extends Omit<CalendarEvent, 'start' | 'end'> {
  id: string;
  title: string;
  start: string;
  end: string;
  backgroundColor: string;
  borderColor: string;
}

const CalendarComponent = ({ type }) => {
  const navigate = useNavigate();
  const { events, checkedEvent, isLoading } = useCalendarStore();
  const calendarRef = useRef(null);

  const { initialDate, handleDateChange } = useDateParams();

  const eventsList: CalendarEventViewProps[] = useMemo(() => {
    let list: CalendarEventViewProps[] = [];
    Object.entries(checkedEvent).forEach(([key, checked]) => {
      if (checked) {
        const formattedEventList: CalendarEventViewProps[] = events[key].map(event => ({
          ...event,
          id: event.id,
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
          location: event.location || "",
          backgroundColor: event.backgroundColor,
          borderColor: event.borderColor,
          allDay: type === 'calendar' || (event.start.date && event.end.date)
        }));

        list = list.concat(formattedEventList);
      }
    });

    return list;
  }, [events, checkedEvent, type]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(INITIAL_VIEW[type]);
    }
  }, [type]);

  if (isLoading) {
    return <TableLoadingSpinner width={"3rem"} height={"3rem"} />;
  }

  return (
    <FullCalendar
      ref={calendarRef}
      eventClick={e => {
        e.jsEvent.preventDefault();
        navigate(`${EVENT_DETAIL_URL}?id=${e.event.id}`, { state: "detail" });
      }}
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        googleCalendarPlugin
      ]}
      initialDate={initialDate}
      datesSet={handleDateChange}
      events={eventsList}
      headerToolbar={HEADER_TOOLBAR[type]}
      initialView={INITIAL_VIEW[type]}
      eventDisplay={"auto"}
      eventTextColor={"#001326"}
      height={HEIGHT[type]}
      eventTimeFormat={{
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      }}
      listDayFormat={{
        month: "short",
        day: "numeric",
        year: "numeric"
      }}
      fixedWeekCount={false}
    />
  );
};

export default React.memo(CalendarComponent);
