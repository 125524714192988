import React from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import { SAUDI_CONTEXT } from "@/constants/context";
import {
  Layout,
  ImgWrapper,
  Img,
  DescriptionWrapper,
  MainDescription,
  SubDescription
} from "./saudiStyle";
import useObserver from "@/hooks/page/useObserver";

const Saudi = () => {
  useObserver("contentX");

  return (
    <PageTemplate id="Saudi" backgroundColor={"#000000"}>
      <Layout id="contentX">
        <DescriptionWrapper>
          <MainDescription>{SAUDI_CONTEXT.MAIN}</MainDescription>
          {SAUDI_CONTEXT.SUB.map((item, index) => {
            return <SubDescription key={index}>{item}</SubDescription>;
          })}
        </DescriptionWrapper>
        <ImgWrapper>
          <Img src="/img/saudi.png" alt="saudi" />
        </ImgWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Saudi);
