import { CalendarTime } from "@/store/calendarStore";

type UriObject = {
  [key: string]: number | string | boolean;
};

const qs = {
  convertQueryString: (data: UriObject) => {
    if (!Object.keys(data).length) return "";
    const pairs = [];
    for (let prop in data) {
      if (Object.prototype.hasOwnProperty.call(data, prop)) {
        let k = prop;
        let v = data[prop];
        pairs.push(`${k}=${v}`);
      }
    }
    return pairs.join("&");
  },

  convertStringObject: (searchString: string) => {
    if (!searchString) return false;
    return searchString
      .substring(1)
      .split("&")
      .reduce((result: { [key: string]: string }, next: string) => {
        let pair = next.split("=");
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        return result;
      }, {});
  }
};

const dateSort = <T extends { date: string | number }>(
  arr: T[],
  orderBy = "asc"
) => {
  return arr.sort((a, b) => {
    let dateA = new Date(a.date).getTime();
    let dateB = new Date(b.date).getTime();

    if (orderBy === "desc") return dateB > dateA ? 1 : -1; // 내림차순

    return dateA > dateB ? 1 : -1;
  });
};

const objectToQueryString = (data: UriObject) => {
  if (!Object.keys(data).length) return "";
  const pairs = [];
  for (let prop in data) {
    if (data.hasOwnProperty(prop)) {
      let k = prop;
      let v = data[prop];
      pairs.push(`${k}=${v}`);
    }
  }
  return pairs.join("&");
};

const objectToURL = (data: UriObject) => {
  if (Object.keys(data).length > 0) {
    return "?" + objectToQueryString(data);
  }
  return "";
};

const responsiveCellSizeHandler = (height: number) => {
  if (height <= 800) return 2;
  else if (height <= 900) return 3;
  else if (height <= 1000) return 4;
  else {
    return 5;
  }
};

const streamToJson = async response => {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let result = "";
  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    result += decoder.decode(value, { stream: !done });
  }

  return JSON.parse(result);
};

const padNumber = (num) => {
  return num.toString().padStart(2, "0");
}

const changeDateForm = (start: CalendarTime, end: CalendarTime) => {
  const isAllDay = !!start.date;

  const startDate = start.dateTime
    ? new Date(start.dateTime)
    : new Date(start.date);

  let endDate = end.dateTime ? new Date(end.dateTime) : new Date(end.date);

  if (isAllDay) {
    endDate.setDate(endDate.getDate() - 1);
  }

  // start 날짜가 end 날짜와 같은지 확인
  const isSameDate =
    startDate.toISOString().slice(0, 10) === endDate.toISOString().slice(0, 10);

  // 연도를 포함할지 여부를 결정하기 위한 변수
  const includeYear = startDate.getFullYear() !== endDate.getFullYear();

  // start와 end의 날짜가 같은 경우
  if (isSameDate) {
    // 하루 종일 진행되는 경우
    if (isAllDay) {
      return `${startDate.getFullYear()}.${padNumber(startDate.getMonth() + 1)}.${padNumber(startDate.getDate())}`;
    }
    // 시간이 있는 경우
    else {
      return `${startDate.getFullYear()}.${padNumber(startDate.getMonth() + 1)}.${padNumber(startDate.getDate())} / ${padNumber(startDate.getHours())}:${padNumber(startDate.getMinutes())} - ${padNumber(endDate.getHours())}:${padNumber(endDate.getMinutes())}`;
    }
  } else {
    // start와 end의 날짜가 다른 경우

    if (isAllDay) {
      return `${startDate.getFullYear()}.${padNumber(startDate.getMonth() + 1)}.${padNumber(startDate.getDate())} ~ ${endDate.getFullYear()}.${padNumber(endDate.getMonth() + 1)}.${padNumber(endDate.getDate())}`;
    }

    // 시간이 있는 경우
    else {
      return `${startDate.getFullYear()}.${padNumber(startDate.getMonth() + 1)}.${padNumber(startDate.getDate())} / ${padNumber(startDate.getHours())}:${padNumber(startDate.getMinutes())} - ${endDate.getFullYear()}.${padNumber(endDate.getMonth() + 1)}.${padNumber(endDate.getDate())} / ${padNumber(endDate.getHours())}:${padNumber(endDate.getMinutes())}`;
    }
  }
};

function isSimilar(a, b, threshold) {
  function sequenceMatcher(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
    const m = s1.length;
    const n = s2.length;
    const matrix = Array(m + 1)
      .fill(undefined)
      .map(() => Array(n + 1).fill(0));
    let matches = 0;

    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (s1[i - 1] === s2[j - 1]) {
          matches += 1;
          matrix[i][j] = matrix[i - 1][j - 1] + 1;
        } else {
          matrix[i][j] = Math.max(matrix[i - 1][j], matrix[i][j - 1]);
        }
      }
    }

    return (matches * 2) / (m + n);
  }

  return sequenceMatcher(a, b) >= threshold;
}

const handleGoogleAnalytics = ({ eventType, eventLabel }) => {
  window.gtag("event", eventType, {
    event_label: eventLabel
  });
};

export { handleGoogleAnalytics, isSimilar, changeDateForm, streamToJson, responsiveCellSizeHandler, objectToURL, objectToQueryString, dateSort, qs, padNumber }